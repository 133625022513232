import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { HeadingWithButtons } from "components";
export const _frontmatter = {
  "title": "Page not found",
  "navBarColor": "light",
  "footer": "hidden"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <HeadingWithButtons buttons={[{
      children: "Go big",
      to: "/contact"
    }, {
      children: "Go home",
      to: "/"
    }]} description="Page not found, but you just found a perfect software-house." separator="or" title="404" mdxType="HeadingWithButtons" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      